import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { CrudService } from './crud.abstract.http.service';
import { Observable } from 'rxjs';
import {
  ExerciseAttemptDto,
  ExerciseSessionDto,
  FiltersDto,
  FullExerciseAttemptDto,
  FullExerciseSessionDto,
  FullPlayerDto,
  GetManyExercisesQueryParamsDto,
  LiveDataDto,
  Paginated,
  PostProcessingHistoryDto,
  SignedUrlDto,
} from '@ledsreact/data-models';

@Injectable()
export class ExerciseSessionHttpService extends CrudService<ExerciseSessionDto, FullExerciseSessionDto> {
  constructor(
    protected http: HttpClient,
    @Inject('baseUrl') protected baseUrl: string,
    @Inject('baseUrl$') protected baseUrl$: Observable<string>
  ) {
    super(http, baseUrl, baseUrl$, 'exercise-session');
  }

  private _getHttpParamsFromFilter(filters: GetManyExercisesQueryParamsDto) {
    let params = new HttpParams();
    for (const key of Object.keys(filters)) {
      if (filters[key] != null) {
        if (typeof filters[key] === 'object') {
          if (Array.isArray(filters[key])) {
            if (filters[key].length) {
              params = params.set(key, JSON.stringify(filters[key]));
            }
          } else {
            params = params.set(key, JSON.stringify(filters[key]));
          }
        } else {
          params = params.set(key, filters[key]);
        }
      }
    }
    return params;
  }

  restartPostprocessor() {
    return this.http.get(`${this.baseUrl}/exercise-session/restart-postprocessor`);
  }

  findAllWithFilters(filters: GetManyExercisesQueryParamsDto): Observable<Paginated<FullExerciseSessionDto>> {
    const params = this._getHttpParamsFromFilter(filters);
    return this.http.get<Paginated<FullExerciseSessionDto>>(`${this.baseUrl}/${this.name}`, { params });
  }

  findByUuid(uuid: string): Observable<FullExerciseSessionDto> {
    return this.http.get<FullExerciseSessionDto>(`${this.baseUrl}/${this.name}/by-uuid/${uuid}`);
  }

  partialUpdateAttempt(
    idSession: number,
    idAttempt: number,
    attempt: Partial<ExerciseAttemptDto>
  ): Observable<FullExerciseAttemptDto> {
    return this.http.patch<FullExerciseAttemptDto>(
      `${this.baseUrl}/exercise-session/${idSession}/attempt/${idAttempt}`,
      attempt
    );
  }

  getAttempt(idSession: number, idAttempt: number): Observable<FullExerciseAttemptDto> {
    const params = new HttpParams().set('timezone', Intl.DateTimeFormat().resolvedOptions().timeZone);
    return this.http.get<FullExerciseAttemptDto>(`${this.baseUrl}/exercise-session/${idSession}/attempt/${idAttempt}`, {
      params,
    });
  }

  restartPostProcessorByUuid(uuid: string) {
    return this.http.get<void>(`${this.baseUrl}/exercise-session/${uuid}/restart-postprocessor`);
  }

  restartPostProcessorForAnAttempt(idSession: number, idAttempt: number) {
    return this.http.get<void>(
      `${this.baseUrl}/exercise-session/${idSession}/attempt/${idAttempt}/restart-postprocessor`
    );
  }

  public getComparablePlayerList(idAttempt: number, httpParams?: HttpParams): Observable<Paginated<FullPlayerDto>> {
    return this.http.get<Paginated<any>>(`${this.baseUrl}/exercise-session/players-to-compare/${idAttempt}`, {
      params: httpParams ? httpParams : null,
    });
  }

  public getComparablePlayerAttemptList(
    idAttempt: number,
    idPlayer: number,
    httpParams?: Record<string, any>
  ): Observable<Paginated<FullExerciseAttemptDto>> {
    return this.http.get<Paginated<any>>(
      `${this.baseUrl}/exercise-session/player-attempts-to-compare/player/${idPlayer}/attempt/${idAttempt}`,
      { params: httpParams ? httpParams : null }
    );
  }

  getFilters(params: HttpParams): Observable<FiltersDto> {
    return this.http.get<FiltersDto>(`${this.baseUrl}/exercise-session/filters`, { params });
  }

  getMergedCSVsExport(idSession: number): Observable<SignedUrlDto> {
    const params = new HttpParams().set('timezone', Intl.DateTimeFormat().resolvedOptions().timeZone);
    return this.http.get<SignedUrlDto>(`${this.baseUrl}/exercise-session/${idSession}/csv-export`, { params });
  }

  getZipDatapointsExport(idSession: number): Observable<SignedUrlDto> {
    const params = new HttpParams().set('timezone', Intl.DateTimeFormat().resolvedOptions().timeZone);
    return this.http.get<SignedUrlDto>(`${this.baseUrl}/exercise-session/${idSession}/datapoints-export`, { params });
  }

  getSessionListExport(filters: GetManyExercisesQueryParamsDto): Observable<SignedUrlDto> {
    const params = this._getHttpParamsFromFilter(filters);
    return this.http.get<SignedUrlDto>(`${this.baseUrl}/exercise-session/sessions-export`, { params });
  }

  getPostprocessingHistoryData(): Observable<PostProcessingHistoryDto[]> {
    return this.http.get<PostProcessingHistoryDto[]>(`${this.baseUrl}/exercise-session/post-processing-history`);
  }

  getLiveData(sessionUid: string): Observable<LiveDataDto[]> {
    return this.http.get<LiveDataDto[]>(`${this.baseUrl}/exercise-session/${sessionUid}/live-data`);
  }
}
