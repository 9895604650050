import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { CrudService } from './crud.abstract.http.service';
import { Observable } from 'rxjs';
import {
  ExerciseDto,
  FiltersDto,
  FullExerciseDto,
  MostRecentExerciseDto,
  Paginated,
  UnitSystemEnum,
} from '@ledsreact/data-models';

@Injectable()
export class ExerciseHttpService extends CrudService<ExerciseDto, FullExerciseDto> {
  constructor(
    protected http: HttpClient,
    @Inject('baseUrl') protected baseUrl: string,
    @Inject('baseUrl$') protected baseUrl$: Observable<string>
  ) {
    super(http, baseUrl, baseUrl$, 'exercise');
  }

  getFilters(): Observable<FiltersDto[]> {
    return this.http.get<FiltersDto[]>(`${this.baseUrl}/${this.name}/filters`);
  }

  getExercisesWithFiltersForWebPlatform(filters: HttpParams): Observable<Paginated<ExerciseDto>> {
    return this.http.get<Paginated<ExerciseDto>>(`${this.baseUrl}/${this.name}/webplatform`, { params: filters });
  }

  getExercisesFilteredByUnitSystem(unitSystem: UnitSystemEnum): Observable<ExerciseDto[]> {
    return this.http.get<ExerciseDto[]>(`${this.baseUrl}/${this.name}/unit-system/${unitSystem}`);
  }

  getExercisesFilteredByUnitSystemAndType(unitSystem: string, idType: number): Observable<ExerciseDto[]> {
    return this.http.get<ExerciseDto[]>(`${this.baseUrl}/${this.name}/unit-system/${unitSystem}/type/${idType}`);
  }

  getCustomExerciseByUnitSystemAndType(unitSystem: UnitSystemEnum, idType: number): Observable<FullExerciseDto> {
    return this.http.get<FullExerciseDto>(
      `${this.baseUrl}/${this.name}/unit-system/${unitSystem}/type/${idType}/custom`
    );
  }

  getRecentExercises(limit = 1, clubId?: number | null): Observable<MostRecentExerciseDto[]> {
    let params = new HttpParams().set('limit', limit.toString());

    if (clubId) {
      params = params.set('clubId', clubId.toString());
    }

    return this.http.get<MostRecentExerciseDto[]>(`${this.baseUrl}/${this.name}/recent`, { params });
  }
}
