<div class="grid-x height-100">
  <div class="cell small-{{ firstColumnWidth }} bg-extra-light-grey">
    <ng-content select="[column-left]"></ng-content>
  </div>
  <div
    class="cell small-{{ 12 - firstColumnWidth }} bs-b5-light-grey column-right"
    [class.disp-n]="firstColumnWidth === 12"
  >
    <ng-content select="[column-right]"></ng-content>
  </div>
</div>
