import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { WebpackTranslateLoader } from './shared/services/webpacktranslate.loader';
import { SidebarModule } from './authenticated/sidebar/sidebar.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { environment } from '@webplatform-environment/environment';
import { AppInitService } from './app-init.service';
import { StorageService } from './shared/services/storage.service';
import { AddHeadersInterceptor } from './shared/interceptors/add-headers.interceptor';
import { UserService } from './shared/services/user.service';
import { GetTokenHeaderInterceptor } from './shared/interceptors/get-token-header.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthenticatedUserAuthGuard } from './shared/guards/authenticated-user.guard';
import { ActiveProfileGuard } from './shared/guards/active-profile.guard';
import { SnackBarModule } from './shared/modules/snack-bar.module';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarService } from './shared/services/snack-bar.service';
import { OverlayModule } from '@angular/cdk/overlay';
import { ErrorInterceptor } from './shared/interceptors/error.interceptor';
import { Router } from '@angular/router';
import { AppService } from './app.service';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { FeaturePageGuard } from './shared/guards/feature-page.guard';
import { CommonModule } from '@angular/common';
import { ChartsService } from './shared/services/charts.service';
import {
  ConeHttpService,
  FeatureMenuHttpService,
  MeHttpService,
  PlayerHttpService,
  ProfileHttpService,
  SportHttpService,
  UserHttpService,
} from '@ledsreact/angular-http-services';
import { TranslationService } from './shared/services/translation.service';
import { LedsreactMissingTranslationHelper } from './shared/class/missing-translation-handler';
import { of } from 'rxjs';

export function getInitialApplicationData(appInitService: AppInitService): () => Promise<any> {
  return (): Promise<any> => {
    return appInitService.init();
  };
}

function getApiURI(): string {
  if (window.location.href.includes('us.ledsreact.com')) {
    return 'https://api.us.ledsreact.com/api';
  } else if (window.location.href.includes('eu.ledsreact.com')) {
    return 'https://api.eu.ledsreact.com/api';
  }
  return environment.api + '/api';
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    SidebarModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: WebpackTranslateLoader,
      },
      missingTranslationHandler: [{ provide: MissingTranslationHandler, useClass: LedsreactMissingTranslationHelper }],
    }),
    BrowserAnimationsModule,
    OverlayModule,
    SnackBarModule,
    MatDialogModule,
    CommonModule,
  ],
  providers: [
    {
      provide: 'baseUrl',
      useValue: getApiURI(),
    },
    {
      // TODO implement dynamic region selection
      provide: 'baseUrl$',
      useFactory: () => of(getApiURI()),
    },
    UserHttpService,
    FeatureMenuHttpService,
    MatDialog,
    StorageService,
    ConeHttpService,
    MeHttpService,
    SportHttpService,
    ProfileHttpService,
    UserService,
    AppInitService,
    AuthenticatedUserAuthGuard,
    ActiveProfileGuard,
    FeaturePageGuard,
    MatSnackBar,
    SnackBarService,
    PlayerHttpService,
    AppService,
    ChartsService,
    TranslationService,
    {
      provide: APP_INITIALIZER,
      useFactory: getInitialApplicationData,
      deps: [AppInitService, Injector],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AddHeadersInterceptor,
      deps: [StorageService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: GetTokenHeaderInterceptor,
      deps: [StorageService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      deps: [Router],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
