import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ExerciseMetricAnalysisQueryDto, ExerciseMetricAnalysisResponseDto } from '@ledsreact/data-models';

@Injectable()
export class ExerciseAttemptMetricsHttpService {
  constructor(protected http: HttpClient, @Inject('baseUrl') protected baseUrl: string) {}

  analyze(query: ExerciseMetricAnalysisQueryDto): Observable<ExerciseMetricAnalysisResponseDto> {
    // Delete undefined values from query
    Object.keys(query).forEach((key) => {
      if (query[key] === undefined || query[key] === null) {
        delete query[key];
      }
    });
    const params = new HttpParams({ fromObject: query as any });
    return this.http.get<ExerciseMetricAnalysisResponseDto>(`${this.baseUrl}/exercise-attempt-metrics/analysis`, {
      params,
    });
  }
}
