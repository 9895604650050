import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-page-container',
  templateUrl: './page-container.component.html',
  styles: [
    `
      .column-right {
        z-index: 1000;
      }
    `,
  ],
})
export class PageContainerComponent {
  @Input() firstColumnWidth: number = 8;
}
